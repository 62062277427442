import { FC, useEffect, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import Modal from "components/Modal";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "../buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import { AddDistributorUserForm } from "./AddDistributorUserForm";
import { useInviteDistributorUser } from "api/distributorInvitation/distributorInvitation.endpoints";
import { CreateDistributorInvitationRequestDTO } from "api/distributorInvitation/distributorInvitation.dto";

interface Props {
  distributorId: number;
}

export const AddDistributorUser: FC<Props> = ({ distributorId }) => {
  const { t } = useTranslation();

  // state
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  // api
  const {
    mutate: inviteUser,
    isPending: isInvitingUser,
    isSuccess: isInviteUserSuccess,
  } = useInviteDistributorUser();

  // form
  const {
    register: registerInvitation,
    handleSubmit: handleInvitationSubmit,
    reset: resetInvitation,
    formState: { errors: invitationErrors },
    setValue,
  } = useForm<CreateDistributorInvitationRequestDTO>({
    defaultValues: { distributorId },
  });

  useEffect(() => {
    setValue("distributorId", distributorId);
  }, [distributorId]);

  // submit
  const onInvitationSubmit = handleInvitationSubmit(
    (data: CreateDistributorInvitationRequestDTO) =>
      inviteUser({ ...data, role: Number(data.role) }),
  );

  // modal close
  const onInvitationModalClose = () => {
    setIsInviteUserModalOpen(false);
    setTimeout(() => resetInvitation(), 300);
  };

  // after submit success
  useEffect(() => {
    if (isInviteUserSuccess) {
      onInvitationModalClose();
    }
  }, [isInviteUserSuccess]);

  const renderAddDistributorButton = () => {
    return (
      <div className={`pb-2 flex justify-end`}>
        <SecondaryButton
          onClick={() => setIsInviteUserModalOpen(true)}
          size="small"
        >
          <UserPlusIcon className="h-5 w-5 mr-1" />{" "}
          {t(`distributor.Add distributor`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderAddDistributorButton()}

      <Modal
        isOpen={isInviteUserModalOpen}
        onClose={onInvitationModalClose}
        title={t(`distributor.Add distributor`)}
        rightButtonText={t(`distributor.Send invitation`)}
        rightButtonActionHandler={onInvitationSubmit}
        isLoading={isInvitingUser}
        loadingText={t(`distributor.Sending invitation...`)}
        leftButtonAction={onInvitationModalClose}
        leftButtonText={t(`Close`)}
      >
        <AddDistributorUserForm
          register={
            registerInvitation as UseFormRegister<CreateDistributorInvitationRequestDTO>
          }
          errors={invitationErrors}
        />
      </Modal>
    </>
  );
};
