import { useAssetOwnersPagination } from "api/assetOwner/assetOwner.endpoints";
import { isEmptyArray } from "helpers/array";
import { FC, useState } from "react";
import { useInstaller } from "api/installer/installer.endpoints";
import { AssetOwnersEmptyState } from "components/assetOwner/AssetOwnersEmptyState";
import { AddAssetOwner } from "./AddAssetOwner";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { Role } from "enums/role.enum";
import { useTranslation } from "react-i18next";
import { AssetOwnerTable } from "./AssetOwnerTable";
import { isInstaller } from "../../helpers/role";
import { AssetOwnerList } from "./AssetOwnerList";
import { useNavigate } from "react-router-dom";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { GetAssetOwnerItemsWithPaginationResponseDTO } from "../../api/assetOwner/assetOwner.dto";
import { getAssetOwnerPageRoute } from "../../helpers/assetOwner";
import { isMobile } from "react-device-detect";

interface Props {
  userRole: Role;
  distributorId?: number;
  installerId?: number;
}

export const AssetOwners: FC<Props> = ({
  userRole,
  distributorId,
  installerId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Pagination query string state is defined to store the query string in the parent component
  const [queryString, setQueryString] = useState<string>("");

  // Infinite query to get asset owners with pagination
  const paginationQuery: UseInfiniteQueryResult<
    InfiniteData<GetAssetOwnerItemsWithPaginationResponseDTO>
  > = useAssetOwnersPagination(queryString, {
    installerId,
    distributorId,
  });

  // Extract relevant information for this component from the pagination query
  const {
    data: assetOwners,
    isLoading: isLoadingAssetOwners,
    isError: isErrorAssetOwners,
    isSuccess: isSuccessAssetOwners,
  } = paginationQuery;

  const onAssetOwnerClick = (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => {
    navigate(
      getAssetOwnerPageRoute(
        userRole,
        assetOwnerId,
        installerId,
        distributorId,
      ),
    );
  };

  // Get installer data
  const {
    data: installer,
    isLoading: isLoadingInstaller,
    isError: isErrorInstaller,
  } = useInstaller(installerId);

  const isLoading = isLoadingAssetOwners || isLoadingInstaller;
  const isError = isErrorAssetOwners || isErrorInstaller;

  if (isLoading || isError) {
    return (
      <CustomPlaceholder
        isEmpty={false}
        isLoading={isLoading}
        isError={isError}
        icon={<UserIcon />}
        errorMessage={t(`assetOwnerList.Error`)}
        loaderSize="page"
      />
    );
  }

  if (
    isSuccessAssetOwners &&
    isEmptyArray(assetOwners?.pages.flatMap((page) => page.items)) &&
    queryString === ""
  ) {
    return isInstaller(userRole) && installer ? (
      <AssetOwnersEmptyState
        canInviteAssetOwners={installer.canInviteAssetOwners}
        installerId={installer.id}
      />
    ) : (
      <CustomPlaceholder
        isEmpty={true}
        emptyDataMessage={t(`assetOwnerList.Empty`)}
        isLoading={false}
        isError={false}
        icon={<UserIcon />}
      />
    );
  }

  return (
    // <div className="flex h-full"> // todo: when we'll have a new grid wrapper
    <div className="flex flex-col h-[620px]">
      {isInstaller(userRole) && installer?.canInviteAssetOwners && (
        <AddAssetOwner
          label={t(`assetOwnerList.Add asset owner`)}
          installerId={installer.id}
        />
      )}
      {isMobile ? (
        <AssetOwnerList
          paginationQuery={paginationQuery}
          setPaginationUrl={setQueryString}
          onAssetOwnerClick={onAssetOwnerClick}
          userRole={userRole}
        />
      ) : (
        <AssetOwnerTable
          paginationQuery={paginationQuery}
          setPaginationUrl={setQueryString}
          onAssetOwnerClick={onAssetOwnerClick}
          userRole={userRole}
        />
      )}
    </div>
  );
};
