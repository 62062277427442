import React from "react";

import { Table } from "@tanstack/react-table";
import { TRH } from "./TRH";

interface Props<T> {
  scrollbarWidth: number;
  table: Table<T>;
}

export const THead = <T,>({
  scrollbarWidth,
  table,
}: Props<T>): React.ReactElement => {
  return (
    <div
      className="bg-flowerSky-50 sticky top-0 z-10 h-11 rounded-tl-lg pl-2 rounded-tr-lg"
      style={
        scrollbarWidth
          ? { paddingRight: `${scrollbarWidth}px` }
          : { paddingRight: "0.5rem" }
      }
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <TRH key={headerGroup.id} headerGroup={headerGroup} />
      ))}
    </div>
  );
};
