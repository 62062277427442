import React, { FC } from "react";
import { GetAssetOwnerItemResponseDTO } from "../../api/assetOwner/assetOwner.dto";
import { InfiniteQueryResult } from "../../api/pagination/pagination.type";
import { List } from "../listBeta/List";
import { useTranslation } from "react-i18next";
import { MapPinIcon, ServerIcon, UserIcon } from "@heroicons/react/24/outline";
import { getAssetOwnerName } from "../../helpers/assetOwnerList";
import { FlowerHubStatusTag } from "../tags/FlowerHubStatusTag";
import { isFlower } from "../../helpers/role";
import { Role } from "../../enums/role.enum";
import { ElementDef } from "../listBeta/LI";

interface Props {
  paginationQuery: InfiniteQueryResult<GetAssetOwnerItemResponseDTO>;
  setPaginationUrl: React.Dispatch<React.SetStateAction<string>>;
  onAssetOwnerClick: (
    assetOwnerId: number,
    installerId: number,
    distributorId: number | undefined,
  ) => void;
  userRole: Role;
}

export const AssetOwnerList: FC<Props> = ({
  paginationQuery,
  setPaginationUrl,
  onAssetOwnerClick,
  userRole,
}) => {
  const { t } = useTranslation();

  const handleRowClick = (row: GetAssetOwnerItemResponseDTO) => {
    if (row) onAssetOwnerClick(row.id, row.installerId, row.distributorId);
  };

  // As Table component has columns, elements should contain left and right elements that will be displayed in the list
  // I defined this function to make the List component more generic
  const elements: ElementDef<GetAssetOwnerItemResponseDTO> = (
    element: GetAssetOwnerItemResponseDTO,
  ) => {
    return {
      left: [
        {
          key: "user",
          cell: () => (
            <div className="flex items-center text-sm font-medium truncate text-gray-500">
              <UserIcon
                className="h-5 w-5 mr-1.5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <span>{getAssetOwnerName(element)}</span>
            </div>
          ),
        },
        {
          key: "address",
          cell: () => (
            <div className="flex items-center text-sm font-medium truncate text-flowerSky-600">
              <MapPinIcon
                className="h-5 w-5 mr-1.5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <div className="flex flex-col items-start text-sm text-gray-500">
                <span>{element.address.street}</span>
                <span>
                  {element.address.postalCode} {element.address.city}
                </span>
              </div>
            </div>
          ),
        },
      ],
      right: [
        {
          key: "serialNumber",
          cell: () => (
            <div className="flex items-center text-sm text-gray-500">
              {element?.asset?.id
                ? element.asset?.serialNumber
                : "Not installed"}
              <ServerIcon
                className="h-5 w-5 ml-1.5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
            </div>
          ),
        },
        {
          key: "flowerHubStatus",
          cell: () =>
            element?.asset?.id ? (
              <FlowerHubStatusTag
                flowerHubStatus={element?.asset?.flowerHubStatus}
                isFlowerUser={isFlower(userRole)}
              />
            ) : undefined,
        },
      ],
    };
  };

  return (
    <List
      paginationQuery={paginationQuery}
      elements={elements}
      onSelectRow={handleRowClick}
      setPaginationUrl={setPaginationUrl}
      title={`${t("assetOwnerList.AssetOwners")}`}
      searchPlaceholder={`${t("assetOwnerList.Search")}`}
    />
  );
};
