import {
  GetComponentStatusItemResponseDTO,
  GetFlowerHubProgressResponseDTO,
} from "./flowerHub.dto";
import api from "../axiosInstance";
import { useQuery } from "@tanstack/react-query";

const ENDPOINT = "/flower-hub";

export enum FlowerHubQueryKeys {
  GetProgress = "get-progress",
  GetComponents = "get-components",
}

export const useFlowerHubProgress = (serialNumber: string) => {
  return useQuery({
    queryKey: [FlowerHubQueryKeys.GetProgress, serialNumber],
    queryFn: async (): Promise<GetFlowerHubProgressResponseDTO> =>
      (await api.get(`${ENDPOINT}/progress/${serialNumber}`)).data,
    refetchInterval: 60000, // Refetch every 60 seconds when component loads
  });
};

export const useFlowerHubComponents = (serialNumber: string) => {
  return useQuery({
    queryKey: [FlowerHubQueryKeys.GetComponents, serialNumber],
    queryFn: async (): Promise<GetComponentStatusItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/components/${serialNumber}`)).data,
  });
};
