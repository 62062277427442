import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "components/buttons/SecondaryButton";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import {
  GetDistributorResponseDTO,
  UpdateDistributorRequestDTO,
} from "api/distributor/distributor.dto";
import { useUpdateDistributor } from "api/distributor/distributor.endpoints";
import { EditDistributorForm } from "./EditDistributorForm";

interface Props {
  distributor: GetDistributorResponseDTO;
}

export const EditDistributor: FC<Props> = ({ distributor }) => {
  const { t } = useTranslation();

  const [isEditDistributorModalOpen, setIsEditDistributorModalOpen] =
    useState<boolean>(false);

  // api
  const {
    mutate: updateDistributor,
    isPending: isUpdating,
    isSuccess,
  } = useUpdateDistributor(distributor.id);

  // form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UpdateDistributorRequestDTO>();

  // submit
  const onSubmit = handleSubmit((data: UpdateDistributorRequestDTO) =>
    updateDistributor(data),
  );

  // modal close
  const onEditDistributorModalClose = () => {
    setIsEditDistributorModalOpen(false);
    setTimeout(() => reset(), 300);
  };

  // after submit success
  useEffect(() => {
    if (isSuccess) {
      onEditDistributorModalClose();
    }
  }, [isSuccess]);

  // Populate or clear the form
  useEffect(() => {
    if (isEditDistributorModalOpen) {
      reset(distributor);
    }
  }, [isEditDistributorModalOpen]);

  const renderEditDistributorButton = () => {
    return (
      <div className="py-5 flex justify-end">
        <SecondaryButton onClick={() => setIsEditDistributorModalOpen(true)}>
          <PencilSquareIcon className="h-5 w-5 mr-1" />
          {t(`distributor.Edit`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderEditDistributorButton()}

      <Modal
        isOpen={isEditDistributorModalOpen}
        rightButtonActionHandler={onSubmit}
        title={`${t(`distributor.Edit`)} ${distributor.name}`}
        rightButtonText={t(`Save`)}
        onClose={onEditDistributorModalClose}
        isLoading={isUpdating}
        leftButtonAction={onEditDistributorModalClose}
        leftButtonText={t(`Close`)}
      >
        <EditDistributorForm register={register} errors={errors} />
      </Modal>
    </>
  );
};
