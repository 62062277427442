import { useNotification } from "hooks/useNotification";
import api from "../axiosInstance";
import {
  CreateAssetRequestDTO,
  CreateAssetResponseDTO,
  GetAssetItemResponseDTO,
  GetAssetResponseDTO,
  GetAssetRevenueWithAssetModelResponseDTO,
  GetAssetStatisticsResponseDTO,
  GetCheckSerialNumberResponseDTO,
  UpdateAssetRequestDTO,
  UpdateAssetResponseDTO,
} from "./asset.dto";
import { AssetOwnerQueryKeys } from "api/assetOwner/assetOwner.endpoints";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const ENDPOINT = "/asset";

export enum AssetQueryKeys {
  GetAll = "asset-get-assets",
  GetOne = "asset-get-asset",
  GetRevenue = "asset-get-revenue",
  CheckSerialNumber = "asset-check-serial-number",
  GetAssetStatistics = "asset-get-asset-statistics",
}

export const useAssets = () =>
  useQuery({
    queryKey: [AssetQueryKeys.GetAll],
    queryFn: async (): Promise<GetAssetItemResponseDTO[]> =>
      (await api.get(ENDPOINT)).data,
  });

export const useAssetRevenue = (id?: number) => {
  return useQuery({
    queryKey: [AssetQueryKeys.GetRevenue, id],
    queryFn: async (): Promise<GetAssetRevenueWithAssetModelResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/revenue`)).data,
    enabled: !!id,
  });
};

export const useAsset = (id?: number) => {
  return useQuery({
    queryKey: [AssetQueryKeys.GetOne, id],
    queryFn: async (): Promise<GetAssetResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}`)).data,
    enabled: !!id,
  });
};

export const useCheckSerialNumber = (
  isPollingEnabled: boolean,
  id?: number,
) => {
  return useQuery({
    queryKey: [AssetQueryKeys.CheckSerialNumber, id],
    queryFn: async (): Promise<GetCheckSerialNumberResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/check-serial-number`)).data,
    enabled: isPollingEnabled,
    refetchInterval: 1000,
  });
};

export const useAssetStatistics = (id: number) => {
  return useQuery({
    queryKey: [AssetQueryKeys.GetAssetStatistics, id],
    queryFn: async (): Promise<GetAssetStatisticsResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/statistics`)).data,
  });
};

export const useCreateAsset = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateAssetRequestDTO,
    ): Promise<CreateAssetResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: (data: CreateAssetResponseDTO) => {
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetOne, data.assetOwnerId],
      });
      queryClient.invalidateQueries({
        queryKey: [AssetOwnerQueryKeys.GetAllPagination],
      });
    },
    onError: () => {
      notification.error();
    },
  });
};

export const useUpdateAsset = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: {
      payload: UpdateAssetRequestDTO;
      id: number;
    }): Promise<UpdateAssetResponseDTO> =>
      (await api.put(`${ENDPOINT}/${data.id}`, data.payload)).data,

    onSuccess: (data: UpdateAssetResponseDTO) => {
      queryClient.invalidateQueries({
        queryKey: [AssetQueryKeys.GetOne, data.id],
      });
    },
    onError: () => {
      notification.error(
        "Failed to update site ids, please double check site ids and try again.",
      );
    },
  });
};
