import { FC, ReactNode } from "react";
import { classNames } from "../../helpers/classNames";

interface Props {
  children: ReactNode;
  size?: "small" | "big";
}

export const ToolBar: FC<Props> = ({ children, size = "big" }) => {
  return (
    <div
      className={classNames(
        "flex rounded-lg shadow-sm max-w-md align-middle border border-gray-700 items-stretch",
        size === "small" ? "w-44" : "w-80",
      )}
    >
      <div className="relative flex items-stretch w-full justify-between">
        {children}
      </div>
    </div>
  );
};
