import { useNotification } from "hooks/useNotification";
import api from "../axiosInstance";
import {
  CreateInstallerRequestDTO,
  GetInstallerItemResponseDTO,
  GetInstallerResponseDTO,
  GetInstallerUsersResponseDTO,
  UpdateInstallerCanInviteAssetOwnersRequestDTO,
  UpdateInstallerRequestDTO,
  UpdateInstallerResponseDTO,
} from "./installer.dto";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ErrorResponse } from "../../types/errorResponse.type";

const ENDPOINT = "/installer";

export enum InstallerQueryKeys {
  GetOne = "installer-get-one",
  GetUsers = "installer-get-installer-users",
  GetAll = "installer-get-all",
}

export const useInstallers = (
  distributorId: number,
  filterByDistributorId: boolean,
) => {
  const parameter = filterByDistributorId
    ? `?distributorId=${distributorId}`
    : "";

  return useQuery({
    queryKey: [InstallerQueryKeys.GetAll, parameter],
    queryFn: async (): Promise<GetInstallerItemResponseDTO[]> =>
      (await api.get(`${ENDPOINT}${parameter}`)).data,
  });
};

export const useInstallerUsers = (id: number) =>
  useQuery({
    queryKey: [InstallerQueryKeys.GetUsers, id],
    queryFn: async (): Promise<GetInstallerUsersResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}/user`)).data,
    initialData: { users: [], invitations: [] },
  });

export const useInstaller = (id?: number) =>
  useQuery({
    queryKey: [InstallerQueryKeys.GetOne, id],
    queryFn: async (): Promise<GetInstallerResponseDTO> =>
      (await api.get(`${ENDPOINT}/${id}`)).data,
    enabled: !!id,
  });

export const useCreateInstaller = () => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateInstallerRequestDTO,
    ): Promise<UpdateInstallerResponseDTO> =>
      (await api.post(ENDPOINT, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [InstallerQueryKeys.GetAll] });
      notification.info(`${data.name} created`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};

export const useUpdateInstaller = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateInstallerRequestDTO,
    ): Promise<UpdateInstallerResponseDTO> =>
      (await api.put(`${ENDPOINT}/${id}`, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetOne, data.id],
      });
      notification.info(`${data.name} updated`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
  });
};

export const useUpdateInstallerCanInviteAssetOwners = (id: number) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: UpdateInstallerCanInviteAssetOwnersRequestDTO,
    ): Promise<UpdateInstallerResponseDTO> =>
      (await api.put(`${ENDPOINT}/${id}/canInviteAssetOwners`, payload)).data,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [InstallerQueryKeys.GetOne, data.id],
      });
      queryClient.invalidateQueries({ queryKey: [InstallerQueryKeys.GetAll] });
      notification.info(`${data.name} updated`);
    },
    onError: () => {
      notification.error();
    },
  });
};
