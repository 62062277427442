import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import SecondaryButton from "../buttons/SecondaryButton";
import { useForm } from "react-hook-form";
import { useUpdateZavannSiteIds } from "api/assetOwner/assetOwner.endpoints";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {
  GetElectricityAgreementResponseDTO,
  UpdateZavannSiteIdsRequestDTO,
} from "api/assetOwner/assetOwner.dto";
import { EditZavannSiteIdsForm } from "./EditZavannSiteIdsForm";

interface Props {
  assetOwnerId: number;
  electricityAgreement: GetElectricityAgreementResponseDTO;
}

export const EditZavannSiteIds: FC<Props> = ({
  assetOwnerId,
  electricityAgreement,
}) => {
  const { t } = useTranslation();

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  // api
  const {
    mutate: updateZavannSiteIds,
    isPending: isUpdating,
    isSuccess: isUpdateSuccess,
  } = useUpdateZavannSiteIds(assetOwnerId);

  // form
  const {
    register: registerUpdateZavannSiteIds,
    handleSubmit: handleUpdateZavannSiteIdsSubmit,
    reset: resetUpdateZavannSiteIds,
    formState: { errors: updateZavannSiteIdsErrors },
  } = useForm<UpdateZavannSiteIdsRequestDTO>();

  // submit
  const onUpdateZavannSiteIdsSubmit = handleUpdateZavannSiteIdsSubmit(
    (payload: UpdateZavannSiteIdsRequestDTO) => {
      updateZavannSiteIds(payload);
    },
  );

  // modal close
  const onUpdateZavannSiteIdsModalClose = () => {
    setIsEditModalOpen(false);
    setTimeout(() => resetUpdateZavannSiteIds(), 300);
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      onUpdateZavannSiteIdsModalClose();
    }
  }, [isUpdateSuccess]);

  // Populate or clear the form every time isEditModalOpen is set to true
  useEffect(() => {
    if (isEditModalOpen) {
      resetUpdateZavannSiteIds({
        zavannNewAccountConsumptionSiteId:
          electricityAgreement.consumption?.siteId,
        zavannNewAccountProductionSiteId:
          electricityAgreement.production?.siteId,
      });
    }
  }, [isEditModalOpen]);

  return (
    <>
      <div className="py-5 pr-4 flex justify-end">
        <SecondaryButton onClick={() => setIsEditModalOpen(true)}>
          <PencilSquareIcon className="h-5 w-5 mr-1" />{" "}
          {t(`editZavannSiteIdsForm.Edit Zavann site ids`)}
        </SecondaryButton>
      </div>

      <Modal
        title={t(`editZavannSiteIdsForm.Edit Zavann site ids`)}
        rightButtonText={t(`Save`)}
        rightButtonActionHandler={onUpdateZavannSiteIdsSubmit}
        isOpen={isEditModalOpen}
        onClose={onUpdateZavannSiteIdsModalClose}
        isLoading={isUpdating}
      >
        <EditZavannSiteIdsForm
          register={registerUpdateZavannSiteIds}
          errors={updateZavannSiteIdsErrors}
        />
      </Modal>
    </>
  );
};
