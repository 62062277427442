import { useCreatePrequalificationGroup } from "api/flowerSettings/flowerSettings.endpoints";
import Modal from "components/Modal";
import { FC, useEffect, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import SecondaryButton from "components/buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import { BRP } from "enums/brp.enum";
import {
  getEmptyPrequalificationGroupDTO,
  CreatePrequalificationGroupDTO,
  PrequalificationGroupDTO,
} from "types/prequalificationGroup.type";
import { PrequalificationGroupForm } from "./PrequalificationGroupForm";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

interface Props {
  centered?: boolean;
}
export const AddPrequalificationGroup: FC<Props> = ({ centered = false }) => {
  const { t } = useTranslation();

  // state
  const [
    isAddPrequalificationGroupModalOpen,
    setIsAddPrequalificationGroupModalOpen,
  ] = useState(false);

  // api
  const {
    mutate: createPrequalificationGroup,
    isPending: isCreatingPrequalificationGroup,
    isSuccess: isCreatePrequalificationGroupSuccess,
  } = useCreatePrequalificationGroup();

  // form
  const {
    register: registerPrequalificationGroup,
    handleSubmit: handleAddPrequalificationGroupSubmit,
    reset: resetPrequalificationGroup,
    formState: { errors: createPrequalificationGroupErrors },
  } = useForm<CreatePrequalificationGroupDTO>({
    defaultValues: {
      biddingRegion: null,
      brp: BRP.Undefined,
    },
  });

  // submit form
  const onAddPrequalificationGroupSubmit = handleAddPrequalificationGroupSubmit(
    (data: CreatePrequalificationGroupDTO) =>
      createPrequalificationGroup({
        ...data,
        brp: +(data.brp ?? -1),
      }),
  );

  // close modal
  const handleAddAssetModalClose = () => {
    setIsAddPrequalificationGroupModalOpen(false);
    setTimeout(
      () => resetPrequalificationGroup(getEmptyPrequalificationGroupDTO()),
      300,
    );
  };

  // trigger on success
  useEffect(() => {
    if (isCreatePrequalificationGroupSuccess) {
      handleAddAssetModalClose();
    }
  }, [isCreatePrequalificationGroupSuccess]);

  const renderAddPrequalificationGroupButton = () => {
    return (
      <div
        className={`py-5 flex ${centered ? "justify-center" : "justify-end"}`}
      >
        <SecondaryButton
          onClick={() => setIsAddPrequalificationGroupModalOpen(true)}
        >
          <PlusCircleIcon className="h-5 w-5 mr-1" />{" "}
          {t(`prequalificationGroup.Add prequalification group`)}
        </SecondaryButton>
      </div>
    );
  };

  return (
    <>
      {renderAddPrequalificationGroupButton()}

      <Modal
        isOpen={isAddPrequalificationGroupModalOpen}
        rightButtonActionHandler={onAddPrequalificationGroupSubmit}
        onClose={handleAddAssetModalClose}
        title={t(`prequalificationGroup.Add prequalification group`)}
        isLoading={isCreatingPrequalificationGroup}
        leftButtonAction={handleAddAssetModalClose}
        leftButtonText={t(`Close`)}
      >
        <PrequalificationGroupForm
          register={
            registerPrequalificationGroup as UseFormRegister<
              CreatePrequalificationGroupDTO | PrequalificationGroupDTO
            >
          }
          errors={createPrequalificationGroupErrors}
        />
      </Modal>
    </>
  );
};
