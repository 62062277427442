import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Dispatch, FC, Fragment, SetStateAction } from "react";
import { NavigationItem } from "./AppWrapper.helpers";
import { PowerIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { classNames } from "helpers/classNames";
import { FlowerHubLogo } from "styles/Icons";
import { useTranslation } from "react-i18next";

interface Props {
  navigation: NavigationItem[];
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
}
export const MobileMenu: FC<Props> = ({
  navigation,
  sidebarOpen,
  setSidebarOpen,
  logout,
}) => {
  const { t } = useTranslation();

  return (
    <Transition show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 lg:hidden"
        onClose={setSidebarOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div className="fixed inset-0 z-40 flex">
          <TransitionChild
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <DialogPanel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="h-0 flex-1 overflow-y-auto pb-4">
                <nav className="mt-5 space-y-1 px-2">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className={`h-auto w-36 m-4`}
                      src={FlowerHubLogo}
                      alt="Flower Hub"
                    />
                  </div>
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.isCurrentRoute
                          ? "bg-flowerPeach-500 text-flowerPeach-900 border border-flowerPeach-900"
                          : "text-flowerGray-600 hover:text-flowerPeach-900",
                        "group flex items-center px-2 py-2 text-sm font-normal rounded-md border-white",
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.isCurrentRoute
                            ? "text-flowerPeach-900"
                            : "text-flowerGray-600 group-hover:text-flowerPeach-900",
                          "mr-3 flex-shrink-0 h-6 w-6",
                        )}
                        aria-hidden="true"
                      />
                      {t(`navigation.${item.name}`)}
                    </Link>
                  ))}
                </nav>
              </div>
              <div
                onClick={logout}
                className="flex-shrink-0 flex p-4 cursor-pointer"
              >
                <div className="flex-shrink-0 w-full group block cursor-pointer">
                  <div className="flex items-center text-flowerGray-600 hover:text-flowerPeach-900">
                    <PowerIcon
                      className={
                        "text-flowerGray-600 mr-3 flex-shrink-0 h-6 w-6 group-hover:text-flowerPeach-900"
                      }
                      aria-hidden="true"
                    />
                    <span className="text-sm font-medium">
                      {t(`navigation.Sign out`)}
                    </span>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div className="w-14 flex-shrink-0">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
