import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/16/solid";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { flexRender, Header } from "@tanstack/react-table";

interface Props<T> {
  header: Header<T, unknown>;
}

export const TH = <T,>({ header }: Props<T>): React.ReactElement => {
  const arrow = {
    desc: (
      <span>
        <ChevronDownIcon className="w-4 h-4" />
      </span>
    ),
    asc: (
      <span>
        <ChevronUpIcon className="w-4 h-4" />
      </span>
    ),
    default: (
      <span>
        <ChevronUpDownIcon className="w-4 h-4" />
      </span>
    ),
  }[header.column.getIsSorted() || "default"];
  return (
    <div
      {...{
        className: header.column.getCanSort()
          ? "truncate flex flex-1 px-2 text-normal items-center font-medium leading-normal cursor-pointer select-none gap-2"
          : "truncate flex flex-1 px-2",
        onClick: header.column.getToggleSortingHandler(),
      }}
    >
      <span className="truncate">
        {flexRender(header.column.columnDef.header, header.getContext())}
      </span>
      {arrow}
    </div>
  );
};
