import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import React, { FC, useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import qs from "qs";

interface Props {
  setPaginationUrl: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
}

export const SearchField: FC<Props> = ({ placeholder, setPaginationUrl }) => {
  // State used to store the global filter value
  const [searchFilterValue, setSearchFilterValue] = useState<string>("");

  // Debounce the search value to avoid making unnecessary requests
  const debouncedSearchValue = useDebounce(searchFilterValue);

  // Update the global filter value and the pagination url when the search value
  useEffect(() => {
    setSearchFilterValue(debouncedSearchValue);
    setPaginationUrl((queryString) => {
      const parsedQueryString = qs.parse(queryString);
      return qs.stringify(
        {
          ...parsedQueryString,
          searchFilter: searchFilterValue ? searchFilterValue : undefined,
        },
        { arrayFormat: "repeat" },
      );
    });
  }, [debouncedSearchValue]);

  return (
    <div className="flex items-stretch py-1">
      <div className="right-0 flex-col items-center font-medium px-3 py-1">
        <MagnifyingGlassIcon
          className="h-5 w-5 text-gray-700"
          aria-hidden="true"
        />
      </div>
      <input
        className="flex items-center py-1 px-0 h-full w-full text-sm border-0 focus:ring-0 focus:outline-none placeholder-gray-300 "
        placeholder={placeholder ?? ""}
        autoComplete="off"
        type="text"
        onChange={(e) => setSearchFilterValue(e.target.value)}
        value={searchFilterValue}
      />
    </div>
  );
};
