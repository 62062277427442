import {
  AssetStep,
  getAddAssetActionText,
  getLeftButtonAction,
  getLeftButtonActionText,
  getStepAction,
  getSteps,
  isRightButtonActionDisabled,
  isRightButtonVisible,
} from "components/installationFlow/installFlowerHub.helpers";
import Modal from "components/Modal";
import React, { useEffect, useState } from "react";
import { InverterType } from "enums/inverterType.enum";
import { InstallFlowerHubSteps } from "components/installationFlow/InstallFlowerHubSteps";
import {
  useCheckSerialNumber,
  useCreateAsset,
} from "api/asset/asset.endpoints";
import { AssetInformation } from "components/installationFlow/steps/AssetInformationStep";
import { Fuse } from "components/installationFlow/bessTypes";
import { useTranslation } from "react-i18next";
import SecondaryButton from "components/buttons/SecondaryButton";

interface Props {
  assetOwnerId: number;
}

export const InstallFlowerHub: React.FC<Props> = ({ assetOwnerId }) => {
  const { t } = useTranslation();

  // states
  const [currentStep, setCurrentStep] = useState<AssetStep>(
    AssetStep.CONNECT_ETH,
  );

  const [assetInformation, setAssetInformation] = useState<AssetInformation>(
    {},
  );

  const [serialNumber, setSerialNumber] = useState<string | undefined>(
    undefined,
  );

  const [isSerialNumberValidated, setIsSerialNumberValidated] =
    useState<boolean>(false);

  const [fuse, setFuse] = useState<Fuse>();

  const [isAddAssetModalOpen, setIsAddAssetModalOpen] =
    useState<boolean>(false);

  // api
  const {
    data: asset,
    mutate: createAsset,
    isPending: isAddingAsset,
  } = useCreateAsset();

  // serial number
  useEffect(() => {
    if (asset?.isInstalled) {
      setIsSerialNumberValidated(true);
    }
  }, [asset]);

  const { data: serialNumberCheck } = useCheckSerialNumber(
    asset?.isInstalled === false && !isSerialNumberValidated,
    asset?.id,
  );

  useEffect(() => {
    if (serialNumberCheck?.isInstalled) {
      setIsSerialNumberValidated(true);
    }
  }, [serialNumberCheck]);

  // submit
  const onSubmitAsset = () => {
    if (
      assetInformation.assetModel &&
      assetInformation.configuration &&
      assetInformation.manufacturer &&
      assetInformation.inverterPowerCapacity &&
      fuse &&
      serialNumber
    ) {
      return createAsset({
        assetModelId: assetInformation.assetModel.dbEntryId,
        assetOwnerId: assetOwnerId,
        inverterPowerCapacity: assetInformation.inverterPowerCapacity,
        bessPowerCapacity: assetInformation.configuration.ratedPower,
        bessCapacity: assetInformation.configuration.ratedEnergy,
        serialNumber: serialNumber,
        // We are hardcoding this until we have a need for other inverter types
        inverterType: InverterType.Hybrid,
        fuseSize: fuse.ampere,
      });
    }
  };

  // modal close
  const handleAddAssetModalClose = () => {
    setIsAddAssetModalOpen(false);
  };

  return (
    <>
      <div className="flex justify-center m-4">
        <SecondaryButton
          onClick={() => setIsAddAssetModalOpen(true)}
          size="large"
        >
          {t(`flowerHub.InstallFlowerHub`)}
        </SecondaryButton>
      </div>

      <Modal
        isOpen={isAddAssetModalOpen}
        onClose={handleAddAssetModalClose}
        isLoading={isAddingAsset}
        title={t(`batteryOverview.Install Flower Hub`)}
        leftButtonText={t(
          `batteryOverview.${getLeftButtonActionText(currentStep)}`,
        )}
        leftButtonAction={getLeftButtonAction(currentStep, setCurrentStep)}
        rightButtonText={t(
          `batteryOverview.${getAddAssetActionText(currentStep)}`,
        )}
        rightButtonActionHandler={getStepAction(
          currentStep,
          setCurrentStep,
          handleAddAssetModalClose,
          onSubmitAsset,
        )}
        rightButtonDisabled={isRightButtonActionDisabled(
          currentStep,
          assetInformation,
          serialNumber,
          isSerialNumberValidated,
        )}
        isRightButtonHidden={
          !isRightButtonVisible(currentStep, isSerialNumberValidated)
        }
      >
        <InstallFlowerHubSteps
          currentStep={currentStep}
          assetInformation={assetInformation}
          setAssetInformation={setAssetInformation}
          assetSerialNumber={serialNumber}
          setAddAssetSerialNumber={setSerialNumber}
          steps={getSteps(
            currentStep,
            assetInformation,
            serialNumber,
            asset?.isInstalled,
          )}
          isConnected={isSerialNumberValidated}
          fuse={fuse}
          setFuse={setFuse}
        />
      </Modal>
    </>
  );
};
