import { FC } from "react";
import { useTranslation } from "react-i18next";
import { hasDifferentContactEmail } from "../../helpers/assetOwner";
import { Address } from "../Address";
import { AssetOwnerStatusTag } from "components/tags/AssetOwnerStatusTag";
import {
  Item,
  Label,
  List,
  Value,
  ValueContainer,
} from "components/descriptionList";
import { useAssetOwnerProfile } from "api/assetOwner/assetOwner.endpoints";
import CustomPlaceholder from "components/CustomPlaceholder";
import { AssetOwnerStatus } from "enums/assetOwnerStatus.enum";
import { UserIcon } from "@heroicons/react/24/outline";

import { EditAssetOwnerProfile } from "components/assetOwner/EditAssetOwnerProfile";
import { DeleteAssetOwner } from "components/assetOwner/DeleteAssetOwner";
import { ResendAssetOwnerInvitation } from "./ResendAssetOwnerInvitation";
import { Role } from "enums/role.enum";
import {
  isAssetOwner,
  isDistributor,
  isFlower,
  isInstaller,
} from "../../helpers/role";
import { EditAssetOwnerInvitation } from "./EditAssetOwnerInvitation";

interface Props {
  assetOwnerId: number;
  userRole: Role;
  hasAsset?: boolean;
  showEditButton?: boolean;
}

export const AccountDetails: FC<Props> = ({
  assetOwnerId,
  userRole,
  hasAsset = false,
  showEditButton = false,
}) => {
  const { t } = useTranslation();

  /**
   * Get asset owner profile
   */
  const {
    data: assetOwnerProfile,
    isLoading,
    isError,
  } = useAssetOwnerProfile(assetOwnerId);

  const renderStatusWithActions = () => {
    if (!assetOwnerProfile?.accountStatus) return null;

    return (
      <Item className="relative">
        <Label>{t(`assetOwnerAccountDetails.Status`)}</Label>
        <Value>
          <div className="flex items-center gap-10">
            <AssetOwnerStatusTag
              status={assetOwnerProfile?.accountStatus}
              size="small"
            />
            {assetOwnerProfile.accountStatus !==
              AssetOwnerStatus.AccountVerified && (
              <ResendAssetOwnerInvitation
                assetOwnerId={assetOwnerProfile.id}
                installerId={assetOwnerProfile.installer.id}
                oldInvitationId={assetOwnerProfile.oldInvitationId}
              />
            )}
            {assetOwnerProfile.accountStatus === AssetOwnerStatus.Invited && (
              <EditAssetOwnerInvitation
                assetOwner={{
                  assetOwnerId: assetOwnerProfile.id,
                  mainEmail: assetOwnerProfile.mainEmail,
                  firstName: assetOwnerProfile.firstName,
                  lastName: assetOwnerProfile.lastName,
                  address: assetOwnerProfile.address,
                  installerId: assetOwnerProfile.installer.id,
                }}
                oldInvitationId={assetOwnerProfile.oldInvitationId || -1}
              />
            )}
            {assetOwnerProfile.accountStatus === AssetOwnerStatus.Invited &&
              !hasAsset && (
                <DeleteAssetOwner assetOwnerId={assetOwnerProfile.id} />
              )}
          </div>
        </Value>
      </Item>
    );
  };

  const renderStatus = () => {
    if (!assetOwnerProfile?.accountStatus) return null;

    return (
      <Item>
        <Label>{t(`assetOwnerAccountDetails.Status`)}</Label>
        <Value>
          <AssetOwnerStatusTag
            status={assetOwnerProfile.accountStatus}
            size="small"
          />
        </Value>
      </Item>
    );
  };

  const renderPlaceholder = () => (
    <div className="my-8">
      <CustomPlaceholder
        isError={isError}
        isLoading={isLoading}
        isEmpty={!isLoading && !isError && !assetOwnerProfile}
        icon={<UserIcon />}
        errorMessage={t(
          `assetOwnerAccountDetails.UnableToFetchAssetOwnerAccountDetails`,
        )}
        emptyDataMessage={t(
          `assetOwnerAccountDetails.NoAssetOwnerAccountDetails`,
        )}
        loaderSize="medium"
      />
    </div>
  );

  if (isLoading || !assetOwnerProfile || isError) {
    return renderPlaceholder();
  }

  return (
    <>
      <List>
        <Item>
          <Label>{t(`assetOwnerAccountDetails.Name`)}</Label>
          <Value>{`${assetOwnerProfile.firstName} ${assetOwnerProfile.lastName}`}</Value>
        </Item>
        {(isFlower(userRole) || isDistributor(userRole)) && renderStatus()}
        {isInstaller(userRole) && renderStatusWithActions()}
        <Item>
          <Label>{t(`assetOwnerAccountDetails.Address`)}</Label>
          <Value>
            <Address address={assetOwnerProfile.address} />
          </Value>
        </Item>
        <Item>
          <Label>{t(`assetOwnerAccountDetails.Phone`)}</Label>
          <Value>{assetOwnerProfile.phone ?? "-"}</Value>
        </Item>
        <Item>
          <Label>
            {hasDifferentContactEmail(
              assetOwnerProfile.mainEmail,
              assetOwnerProfile.contactEmail,
            )
              ? t(`assetOwnerAccountDetails.AccountEmail`)
              : t(`assetOwnerAccountDetails.Email`)}
          </Label>
          <Value>{assetOwnerProfile.mainEmail}</Value>
        </Item>
        {hasDifferentContactEmail(
          assetOwnerProfile.mainEmail,
          assetOwnerProfile.contactEmail,
        ) && (
          <Item>
            <Label>{t(`assetOwnerAccountDetails.InvoiceEmail`)}</Label>
            <Value>{assetOwnerProfile.contactEmail}</Value>
          </Item>
        )}

        {isAssetOwner(userRole) && (
          <Item>
            <Label>{t(`assetOwnerAccountDetails.Installer contact`)}</Label>
            <ValueContainer>
              <Value>
                <div className="space-y-1">
                  {!!assetOwnerProfile.installer?.name && (
                    <p>{assetOwnerProfile.installer?.name}</p>
                  )}
                  <Address address={assetOwnerProfile.installer.address} />
                </div>
              </Value>
            </ValueContainer>
          </Item>
        )}
      </List>

      {showEditButton && (
        <EditAssetOwnerProfile assetOwnerProfile={assetOwnerProfile} />
      )}
    </>
  );
};
