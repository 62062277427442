import { FC } from "react";
import { Panel } from "components/Panel";
import { FlowerHubStatusTag } from "components/tags/FlowerHubStatusTag";
import { useLatestBasedOnAssetCreationForFlower } from "api/assetOwner/assetOwner.endpoints";
import { useTranslation } from "react-i18next";

export const LatestAssetOwnersWithAssetList: FC = () => {
  const { t } = useTranslation();
  const {
    data: assetOwners,
    isLoading,
    isError,
  } = useLatestBasedOnAssetCreationForFlower();

  if (isLoading || isError || !assetOwners) return null; // Mitigate placeholder views in dashboard

  return (
    <Panel header={t("flowerHub.Recently installed Flower Hubs")}>
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-0"
              >
                {t("flowerHub.SerialNumber")}
              </th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-0"
              >
                {t("assetOwnerAccountDetails.Name")}
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
              >
                {t("assetOwnerAccountDetails.Address")}
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
              >
                {t("flowerHub.Model")}
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
              >
                {t("flowerHub.Title")}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {assetOwners.map((assetOwner) => (
              <tr key={assetOwner.id}>
                <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-0">
                  {assetOwner.asset.serialNumber}
                </td>
                <td className="whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-0">
                  {assetOwner.installerName}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                  {`${assetOwner.address.postalCode}, ${assetOwner.address.city}`}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                  {`${assetOwner.asset.assetModel?.manufacturer}, ${assetOwner.asset.assetModel.name}`}
                </td>
                <td className="px-3 py-4 ">
                  <FlowerHubStatusTag
                    flowerHubStatus={assetOwner.asset.flowerHubStatus}
                    isFlowerUser={true}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Panel>
  );
};
