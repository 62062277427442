import { UpdateAssetOwnerRequestDTO } from "api/assetOwner/assetOwner.dto";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { PostalCodeInput } from "components/input/PostalCodeInput";
import { TextInput } from "components/input/TextInput";
import { noEmptyStringValidator } from "helpers/validation";
import { FC } from "react";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  register: UseFormRegister<UpdateAssetOwnerRequestDTO>;
  errors: Partial<FieldErrorsImpl<UpdateAssetOwnerRequestDTO>>;
}
export const EditAssetOwnerProfileForm: FC<Props> = ({ register, errors }) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`assetOwnerAccountDetails.First name`)}
        name="firstName"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.firstName?.message}
      />
      <TextInput
        label={t(`assetOwnerAccountDetails.Last name`)}
        name="lastName"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.lastName?.message}
      />
      <TextInput
        label={t(`assetOwnerAccountDetails.Address`)}
        name="address.street"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.address?.message}
      />
      <PostalCodeInput
        label={t(`assetOwnerAccountDetails.Postal code`)}
        name="address.postalCode"
        register={register}
        errorMessage={errors.address?.postalCode?.message}
      />
      <TextInput
        label={t(`assetOwnerAccountDetails.City`)}
        name="address.city"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.address?.city?.message}
      />
      <TextInput
        label={t(`assetOwnerAccountDetails.Phone`)}
        name="phone"
        register={register}
        registerOptions={noEmptyStringValidator()}
        errorMessage={errors.phone?.message}
      />
    </ModalContentWrapper>
  );
};
