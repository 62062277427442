import { FC } from "react";

import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { contactSupport, debuggingGuide } from "constants/externalLinks";
import { Link } from "components/Link";
import { useTranslation } from "react-i18next";

export const FlowerHubSupportContent: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col lg:flex-row mt-5 space-x-6">
      <div className="flex gap-x-3">
        <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-flowerSky-400">
          <ClipboardDocumentListIcon
            aria-hidden="true"
            className="h-4 w-4 text-white"
          />
        </div>
        <Link href={debuggingGuide} target="_blank" rel="noreferrer">
          <span className="text-sm font-semibold leading-6 text-flowerSky-400 underline">
            {t("flowerHubProgress.debuggingGuide")}
          </span>
        </Link>
      </div>
      <div className="flex gap-x-3">
        <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg bg-flowerSky-400">
          <ChatBubbleLeftRightIcon
            aria-hidden="true"
            className="h-4 w-4 text-white"
          />
        </div>
        <Link href={contactSupport} target="_blank" rel="noreferrer">
          <span className="text-sm font-semibold leading-6 text-flowerSky-400 underline">
            {t("flowerHubProgress.contactFlower")}
          </span>
        </Link>
      </div>
    </div>
  );
};
