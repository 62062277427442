import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { useVirtualizer } from "@tanstack/react-virtual";
import { InfiniteQueryResult } from "../../api/pagination/pagination.type";
import { ListStyle } from "./ListStyle";
import { Title } from "./Title";
import { ToolBar } from "../tableBeta/Toolbar";
import { SearchField } from "../tableBeta/SearchField";
import { FilterMenu } from "../tableBeta/FilterMenu";
import { UL } from "./UL";
import { ElementDef, Identifiable, LI } from "./LI";

interface Props<T> {
  paginationQuery: InfiniteQueryResult<T>;
  elements: ElementDef<T>;
  onSelectRow?: (data: T) => void;
  setPaginationUrl: Dispatch<SetStateAction<string>>;
  title?: string;
  searchPlaceholder?: string;
  hasToolbar?: boolean;
  offSetToTriggerPagination?: number;
}

export const List = <T,>({
  paginationQuery,
  elements,
  onSelectRow,
  setPaginationUrl,
  title = "",
  searchPlaceholder = "",
  hasToolbar = true,
  offSetToTriggerPagination = 500,
}: Props<T>): ReactElement => {
  // Reference to the parent div of the list
  const listContainerElement = useRef<HTMLDivElement>(null);

  // Destructure the data from the paginationQuery
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } =
    paginationQuery;

  // Flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page?.items) ?? [],
    [data],
  );

  // Getting updated filters, if any, from the server. Checked only first page, because filters are static (same for each page)
  const filters = useMemo(() => data?.pages?.[0]?.filters ?? [], [data]);

  // Called on scroll to fetch more pages as the user scrolls and reaches bottom of the table body
  const fetchMoreOnBottomReached = useCallback(
    (listContainerElement?: HTMLDivElement | null) => {
      if (listContainerElement) {
        const { scrollHeight, scrollTop, clientHeight } = listContainerElement;
        if (
          scrollHeight - scrollTop - clientHeight < offSetToTriggerPagination && // fetching a new page when 200px from the bottom of the table body
          hasNextPage &&
          !isFetchingNextPage
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetchingNextPage, hasNextPage],
  );

  // Attach scroll event to the container
  useEffect(() => {
    const containerElement = listContainerElement.current;

    if (containerElement) {
      const handleScroll = () => fetchMoreOnBottomReached(containerElement);
      containerElement.addEventListener("scroll", handleScroll);

      // Clean up the event listener on unmount
      return () => containerElement.removeEventListener("scroll", handleScroll);
    }
  }, [fetchMoreOnBottomReached]);

  // Virtualizer for the list rows to improve performance
  const rowVirtualizer = useVirtualizer({
    count: flatData.length,
    getScrollElement: () => listContainerElement.current,
    estimateSize: () => 110,
    overscan: 5,
  });

  return (
    <div className="w-full p-4 rounded-2xl flex flex-col bg-white h-full min-h-128">
      {(title || hasToolbar) && (
        <div className="flex-shrink-0 h-8 justify-between items-center flex mb-4">
          <Title title={title} />
          {hasToolbar && (
            <ToolBar size={"small"}>
              <SearchField
                placeholder={searchPlaceholder}
                setPaginationUrl={setPaginationUrl}
              />
              {!isLoading && filters.length !== 0 && (
                <FilterMenu
                  filters={filters}
                  setPaginationUrl={setPaginationUrl}
                />
              )}
            </ToolBar>
          )}
        </div>
      )}
      <div className="flex-grow overflow-hidden bg-white w-full rounded-lg">
        <ListStyle>
          <div
            ref={listContainerElement}
            className="relative overflow-auto rounded-bl-lg rounded-br-lg h-full"
          >
            <UL rowVirtualizer={rowVirtualizer}>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const row = flatData[virtualRow.index] as T & Identifiable; // Every row must have an id
                return (
                  <LI
                    key={virtualRow.index}
                    virtualRow={virtualRow}
                    row={row}
                    elements={elements}
                    onSelectRow={onSelectRow}
                    rowVirtualizer={rowVirtualizer}
                  />
                );
              })}
            </UL>
            {rowVirtualizer.getVirtualItems().length === 0 && (
              <div className="flex justify-center items-center h-full">
                <span className="text-gray-400">No data available.</span>
              </div>
            )}
          </div>
        </ListStyle>
      </div>
    </div>
  );
};
