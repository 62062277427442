import {
  GetFlowerSettingsResponseDTO,
  UpdateFlowerSettingsRequestDTO,
} from "api/flowerSettings/flowerSettings.dto";
import { useUpdateSettings } from "api/flowerSettings/flowerSettings.endpoints";
import Modal from "components/Modal";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { EditGeneralSettingsForm } from "./EditGeneralSettingsForm";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import SecondaryButton from "components/buttons/SecondaryButton";
import { useTranslation } from "react-i18next";

type Props = { flowerSettings: GetFlowerSettingsResponseDTO };
export const EditGeneralSettings: FC<Props> = ({ flowerSettings }) => {
  const { t } = useTranslation();

  const [isUpdateSettingsModalOpen, setIsUpdateSettingsModalOpen] =
    useState(false);

  // api
  const {
    mutate: updateSettings,
    isPending: isUpdatingSettings,
    isSuccess: isUpdateSettingsSuccess,
  } = useUpdateSettings();

  // form
  const {
    register: registerSettings,
    handleSubmit: handleSettingsSubmit,
    reset: resetSettings,
    formState: { errors: settingsErrors },
  } = useForm<UpdateFlowerSettingsRequestDTO>();

  // populate form with data
  useEffect(() => {
    if (flowerSettings) {
      resetSettings({ ...flowerSettings });
    }
  }, [flowerSettings]);

  // submit
  const onSettingsSubmit = handleSettingsSubmit(
    (data: UpdateFlowerSettingsRequestDTO) => updateSettings(data),
  );

  // close modal
  const handleSettingsModalClose = () => {
    setIsUpdateSettingsModalOpen(false);
    setTimeout(() => resetSettings({ ...flowerSettings }), 300);
  };

  // trigger on successful put
  useEffect(() => {
    if (isUpdateSettingsSuccess) {
      handleSettingsModalClose();
    }
  }, [isUpdateSettingsSuccess]);

  return (
    <>
      <div className="py-5 pr-4 flex justify-end">
        <SecondaryButton onClick={() => setIsUpdateSettingsModalOpen(true)}>
          <PencilSquareIcon className="h-5 w-5 mr-1" />
          {t(`flowerSettings.Edit settings`)}
        </SecondaryButton>
      </div>

      <Modal
        isOpen={isUpdateSettingsModalOpen}
        onClose={handleSettingsModalClose}
        leftButtonAction={handleSettingsModalClose}
        leftButtonText={t(`Close`)}
        title={t(`flowerSettings.Update settings`)}
        rightButtonActionHandler={onSettingsSubmit}
        isLoading={isUpdatingSettings}
        rightButtonText={t(`Save`)}
      >
        <EditGeneralSettingsForm
          register={registerSettings}
          errors={settingsErrors}
        />
      </Modal>
    </>
  );
};
