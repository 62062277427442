import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AccountDetails } from "../../../components/assetOwner/AccountDetails";
import { menuStore } from "store/menu.store";
import { useAssetOwner } from "api/assetOwner/assetOwner.endpoints";
import userStore, { getUserRole } from "store/user.store";
import { getBreadCrumbs } from "../../../helpers/assetOwner";
import TabContainer from "components/tab/TabContainer";
import { Consumption } from "../../../components/consumption/Consumption";
import { FlowerRevenue } from "components/flowerRevenue/FlowerRevenue";
import { SystemNotificationInfo } from "components/systemNotification/SystemNotificationInfo";
import { useActiveZavannSystemNotifications } from "api/system-notification/systemNotification.endpoints";
import { useTranslation } from "react-i18next";
import { ElectricityAgreement } from "../../../components/electricityAgreement/ElectricityAgreement";
import { Battery } from "components/battery/Battery";
import { FlowerHubDetails } from "components/flowerHub/FlowerHubDetails";
import CustomPlaceholder from "components/CustomPlaceholder";
import { UserIcon } from "@heroicons/react/24/outline";
import { isFlower } from "../../../helpers/role";
import { isSystemManagerPresentForAsset } from "helpers/systemManager";

export enum AssetOwnerTabs {
  FlowerHub = "flowerhub",
  Battery = "battery",
  AccountDetails = "account-details",
  ElectricityAgreement = "electricity-agreement",
  Graph = "consumption-graph",
  FlowerRevenue = "flower-revenue",
}

export default function AssetOwnerPage() {
  const params = useParams();
  const assetOwnerId: string = params?.assetOwnerId || "-1";

  const userRole = userStore(getUserRole);

  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });
  const currentTab = searchParams.get("tab") || AssetOwnerTabs.FlowerHub;

  const { t } = useTranslation();

  //Get active zavann system notifications
  const { data: systemNotification } = useActiveZavannSystemNotifications();

  // Get asset owner
  const {
    data: assetOwner,
    isLoading: isLoadingAssetOwner,
    isError: isErrorAssetOwner,
  } = useAssetOwner(+assetOwnerId);

  // breadcrumbs
  const { setBreadCrumbs } = menuStore();

  // Set breadcrumbs and form every time assetOwner updates
  useEffect(() => {
    if (assetOwner) {
      setBreadCrumbs(getBreadCrumbs(assetOwner, userRole));
    }
  }, [assetOwner]);

  if (isLoadingAssetOwner || isErrorAssetOwner || !assetOwner) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isErrorAssetOwner}
          isLoading={isLoadingAssetOwner}
          isEmpty={!isLoadingAssetOwner && !isErrorAssetOwner && !assetOwner}
          icon={<UserIcon />}
          errorMessage={t(`assetOwnerAccountDetails.assetOwnerNotFound`)}
          emptyDataMessage={t(`assetOwnerAccountDetails.assetOwnerNotFound`)}
          loaderSize="page"
        />
      </div>
    );
  }

  const tabs = [
    {
      param: AssetOwnerTabs.FlowerHub,
      label: t(`flowerHub.Title`),
      content: (
        <FlowerHubDetails
          assetOwnerId={assetOwner.id}
          assetId={assetOwner.asset?.id}
          serialNumber={assetOwner.asset?.serialNumber}
          hasSystemManager={isSystemManagerPresentForAsset(assetOwner.asset)}
        />
      ),
      isSelected: currentTab === AssetOwnerTabs.FlowerHub,
    },
    {
      param: AssetOwnerTabs.Battery,
      label: t(`batteryOverview.Title`),
      content: (
        <Battery
          assetOwnerId={+assetOwnerId}
          assetId={assetOwner.asset?.id}
          showEditAsset={isFlower(userRole)}
          userRole={userRole}
        />
      ),
      isSelected: currentTab === AssetOwnerTabs.Battery,
    },
    {
      param: AssetOwnerTabs.AccountDetails,
      label: t(`assetOwnerAccountDetails.Title`),
      content: (
        <AccountDetails
          assetOwnerId={assetOwner.id}
          userRole={userRole}
          hasAsset={!!assetOwner.asset?.id}
          showEditButton={isFlower(userRole)}
        />
      ),
      isSelected: currentTab === AssetOwnerTabs.AccountDetails,
    },
    {
      param: AssetOwnerTabs.ElectricityAgreement,
      label: t(`electricityAgreement.Title`),
      content: (
        <TabContainer>
          {systemNotification ? (
            <SystemNotificationInfo notification={systemNotification} />
          ) : (
            <ElectricityAgreement
              assetOwnerId={assetOwner.id}
              userRole={userRole}
            />
          )}
        </TabContainer>
      ),
      isSelected: currentTab === AssetOwnerTabs.ElectricityAgreement,
    },
  ];

  if (isFlower(userRole)) {
    const flowerUserTabs = [
      {
        param: AssetOwnerTabs.Graph,
        label: t(`graph.Title`),
        content: (
          <TabContainer>
            {systemNotification ? (
              <SystemNotificationInfo notification={systemNotification} />
            ) : (
              <Consumption assetOwnerId={assetOwner.id} />
            )}
          </TabContainer>
        ),
        isSelected: currentTab === AssetOwnerTabs.Graph,
      },
      {
        param: AssetOwnerTabs.FlowerRevenue,
        label: t(`flowerRevenue.Title`),
        content: (
          <FlowerRevenue
            assetId={assetOwner.asset?.id}
            bessCompensationStartDate={assetOwner?.bessCompensationStartDate}
            showLink={false}
          />
        ),
        isSelected: currentTab === AssetOwnerTabs.FlowerRevenue,
      },
    ];

    tabs.push(...flowerUserTabs);
  }

  return <TabPageWrapper tabs={tabs} setTab={setTab} />;
}
