// Custom fetcher function to handle server-side data fetching
import api from "../axiosInstance";
import qs from "qs";

interface fetchTablePagesProps {
  url: string;
  pageParam?: number;
  queryString?: string;
  optionalQueryString?: string;
}

export const fetchTablePages = async ({
  url,
  pageParam,
  queryString = "",
  optionalQueryString = "",
}: fetchTablePagesProps) => {
  const parsedQueryString = qs.parse(queryString);
  const parsedOptionalQueryString = qs.parse(optionalQueryString);
  const paginationQueryString = qs.stringify(
    {
      page: pageParam,
      ...parsedQueryString,
      ...parsedOptionalQueryString,
    },
    { arrayFormat: "repeat" },
  );
  return await api.get(`${url}?${paginationQueryString}`);
};
