import { useMemo } from "react";
/* cspell:disable-next-line */
import { TableOptions } from "@tanstack/react-table";
import { Badge, BadgeColor } from "components/tags/Badge";
import { SystemNotificationStatus } from "enums/systemNotificationType.enum";
import {
  Filter,
  filterFunction,
  getUniqueFilterTags,
} from "components/filters/Filters";
import { SystemNotification } from "api/system-notification/systemNotification.type";
import { useSystemNotifications } from "api/system-notification/systemNotification.endpoints";
import { DeleteSystemNotification } from "./DeleteSystemNotification";
import { AddSystemNotification } from "./AddSystemNotification";
import CustomPlaceholder from "components/CustomPlaceholder";
import { isEmptyArray } from "helpers/array";
import { AddAssetModel } from "../assetModel/AddAssetModel";
import { useTranslation } from "react-i18next";
import { BellAlertIcon } from "@heroicons/react/24/outline";
import { TanStackTable } from "../table/TanStackTable";

export const SystemNotificationList = () => {
  const { t } = useTranslation();

  const {
    data: systemNotifications,
    isLoading,
    isError,
  } = useSystemNotifications();

  const columns: TableOptions<SystemNotification>["columns"] = useMemo(
    () => [
      {
        header: t(`systemNotification.Type`),
        accessorKey: "type",
        filterFn: "includesString",
      },
      {
        header: t(`systemNotification.Start Date`),
        accessorKey: "startDate",
        accessorFn: (row: SystemNotification) =>
          `${new Date(row.startDate).toLocaleString()}`,
      },
      {
        header: t(`systemNotification.End Date`),
        accessorKey: "endDate",
        accessorFn: (row: SystemNotification) =>
          `${new Date(row.endDate).toLocaleString()}`,
      },
      {
        accessorKey: "message",
        header: t(`systemNotification.Message`),
        filterFn: "includesString",
      },
      {
        header: t(`systemNotification.Status`),
        accessorKey: "status",
        cell: (row) => (
          <Badge text={row.getValue()} color={getColor(row.getValue())} />
        ),
        filterFn: filterFunction,
        enableColumnFilter: true,
      },
      {
        header: t(`systemNotification.Action`),
        accessorKey: "action",
        cell: (row) => (
          <DeleteSystemNotification systemNotification={row.row.original} />
        ),
      },
    ],
    [systemNotifications],
  );

  const filters: Filter[] = [
    {
      id: "status",
      name: t(`systemNotification.Status`),
      options: getUniqueFilterTags({
        data: systemNotifications || [],
        columnName: "status",
      }),
    },
  ];

  if (isLoading || isError || systemNotifications === undefined) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError || systemNotifications === undefined}
          isLoading={isLoading}
          isEmpty={false}
          icon={<BellAlertIcon />}
          errorMessage={t(`systemNotification.Error`)}
          loaderSize="page"
        />
      </div>
    );
  }

  if (isEmptyArray(systemNotifications)) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={isEmptyArray(systemNotifications)}
          icon={<BellAlertIcon />}
          emptyDataMessage={t(`systemNotification.Empty`)}
        />
        <AddAssetModel centered={true} />
      </div>
    );
  }

  return (
    <>
      <div className="overflow-hidden md:rounded-lg min-h-80 ring-opacity-5">
        <TanStackTable
          columns={columns}
          data={systemNotifications}
          filters={filters}
        />
      </div>
      <AddSystemNotification />
    </>
  );
};

const getColor = (status: SystemNotificationStatus): BadgeColor => {
  switch (status) {
    case SystemNotificationStatus.OnGoing:
      return "blue";
    case SystemNotificationStatus.Upcoming:
      return "yellow";
    case SystemNotificationStatus.Ended:
      return "green";
    default:
      return "gray";
  }
};
