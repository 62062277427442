import React, { FC, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom"; // It allows to render a child component into a different part of the DOM tree
import { BadgeColor, colorClass } from "../tags/Badge";
import { classNames } from "../../helpers/classNames";

type TooltipSize = "small" | "medium" | "large";
type TooltipPosition = "top" | "bottom" | "left" | "right";

interface Props {
  children: React.ReactNode;
  message: string;
  color?: BadgeColor;
  size?: TooltipSize;
  position?: TooltipPosition;
}

export const Tooltip: FC<Props> = ({
  children,
  message,
  color = "gray",
  size = "medium",
  position = "top",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible && triggerRef.current && tooltipRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      let x = 0;
      let y = 0;
      const offset = 0; // Reduced offset value

      switch (position) {
        case "top":
        case "bottom":
          x = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
          y =
            position === "top"
              ? triggerRect.top - tooltipRect.height - offset
              : triggerRect.bottom + offset;
          break;
        case "left":
        case "right":
          x =
            position === "left"
              ? triggerRect.left - tooltipRect.width - offset
              : triggerRect.right + offset;
          y = triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2;
          break;
      }

      // Constrain to window boundaries
      x = Math.max(8, Math.min(x, window.innerWidth - tooltipRect.width - 8));
      y = Math.max(8, Math.min(y, window.innerHeight - tooltipRect.height - 8));

      setCoords({ x: x + window.scrollX, y: y + window.scrollY });
    }
  }, [isVisible, position]);

  const handleMouseEnter = () => setIsVisible(true);
  const handleMouseLeave = () => setIsVisible(false);

  return (
    <>
      <div
        ref={triggerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="w-full flex"
      >
        {children}
      </div>
      {isVisible &&
        createPortal(
          <div
            ref={tooltipRef}
            className="fixed z-50"
            style={{
              left: `${coords.x}px`,
              top: `${coords.y}px`,
              pointerEvents: "none",
            }}
          >
            <div className={classNames("px-3 py-2", getTooltipSize(size))}>
              <div
                className={classNames(
                  "p-2 text-xs leading-4 text-wrap rounded-lg text-center shadow",
                  colorClass(color),
                )}
              >
                {message}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

const getTooltipSize = (size: TooltipSize): string => {
  switch (size) {
    case "small":
      return "w-32";
    case "medium":
      return "w-52";
    case "large":
      return "w-72";
    default:
      throw Error(`getTooltipSize missing implementation for ${size}`);
  }
};
